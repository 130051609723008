import * as server from "./server";
import * as browser from "./browser";
import {wpnBaseUrl} from "./server";

const WAPP_BUTTON_CLICK_EVENT_TYPE = 20;
const _WPN_WA_BACKGROUND_COLOR = '#1FAF38';
const _WPN_BTN_CLASS = 'wpn-wa-btn-container';
const _WPN_BUY_BTN_SELECTOR = '[data-component="cart.checkout-button"]';

if(typeof LS == "object") {
  window._wpnCart = structuredClone(LS.cart)
}
export function _wpnEnsureWhatsappButton() {
  if(_wpnHasWhatsappButtonInstalled() && _wpnCheckUserForWhatsappButton()) {
    document.addEventListener('cart.viewed', function (cartData) {
      _wpnCreateWhatsappButtonAndListener(window['wpnObject'].wappMessages || _wpnGetDefaultMessages());

      document.addEventListener('cart.updated', function (cartUpdateEvent){
        window._wpnCart.items = cartUpdateEvent.detail.products
      })
    });
  }
}

function _wpnGetDefaultMessages() {
  switch (LS.langCode) {
    case 'pt':
        return {
            WA_PRIMARY_BTN_TEXT: "Enviar para Whatsapp",
            WA_MODAL_TITLE: "Digite seu telefone",
            WA_MODAL_SUBTITLE: "Inclua o código do país e área",
            WA_INPUT_PLACEHOLDER: "+5511988810000",
            WA_SUBMIT_BTN_TEXT: "Enviar",
            WA_SUCCESS_MESSAGE: "Obrigado! Entraremos em contato em breve."
        };
    case 'es':
    default:
      return {
        WA_PRIMARY_BTN_TEXT: "Enviar a Whatsapp",
        WA_MODAL_TITLE: "Ingresa tu teléfono",
        WA_MODAL_SUBTITLE: "Incluye código de país y área",
        WA_INPUT_PLACEHOLDER: "+5491138881000",
        WA_SUBMIT_BTN_TEXT: "Enviar",
        WA_SUCCESS_MESSAGE: "¡Gracias! Te contactaremos pronto."
        };
  }
}

function _wpnCheckUserForWhatsappButton() {
  const allowedUsers = window['wpnObject'].wappUsers || [];
  return LS && LS.customer && allowedUsers.includes(LS.customer);
}

function _wpnCreateWhatsappButtonAndListener(messages){
  const btn = document.querySelector(_WPN_BUY_BTN_SELECTOR);
  if(btn !== null) {
    if(document.querySelector('.' + _WPN_BTN_CLASS) === null) {
      const originalBtnStyles = window.getComputedStyle(btn);

      let cssText = originalBtnStyles.cssText;
      if (!cssText) {
        cssText = Array.from(originalBtnStyles).reduce((str, property) => {
          return `${str}${property}:${originalBtnStyles.getPropertyValue(property)};`;
        }, '');
      }
      const wBtnContainer = document.createElement('div');
      wBtnContainer.classList.add(_WPN_BTN_CLASS);
      wBtnContainer.classList.add('btn');
      wBtnContainer.classList.add('btn-block');
      wBtnContainer.style.marginLeft = '20px';

      const wBtn = document.createElement('a');
      wBtn.addEventListener('click', _wpnOpenPhoneInput);
      wBtn.style.cssText = cssText;
      //TODO This can be improved; consider translations
      //wBtn.innerHTML = '<img src="https://www.titanpush.com/images/chat/10_icon.svg" width="20" alt="" max-width="20" style="vertical-align: inherit; margin-right: 4px; "> Comprar por whatsapp';
      wBtn.innerHTML = messages.WA_PRIMARY_BTN_TEXT;
      //Override some properties
      wBtn.style.background = _WPN_WA_BACKGROUND_COLOR;
      wBtn.style.float = 'none';
      wBtn.style.boxShadow = 'none';
      wBtn.style.border = 'none';
      wBtn.style.height = 'auto';
      wBtn.style.marginTop = wBtn.style.marginBottom;
      wBtn.style.width = '100%';
      wBtn.style.display = 'block';

      wBtnContainer.appendChild(wBtn);

      const formContainer = document.createElement('div');
      formContainer.setAttribute('id', 'wpnWaformContainer');
      wBtnContainer.appendChild(formContainer);

      btn.insertAdjacentElement('afterend', wBtnContainer);
      btn.remove();

      _wpnCreatePhoneModal(cssText, messages);
    }
  }
}

function _wpnBuildCartUrl() {
  // Map over items and transform into "variantID-quantity" strings
  const products = window._wpnCart.items.map(item => {
    return `${item.variant_id}-${item.quantity}`;
  });
  const currentUrl = window.location.href;
  const urlObj = new URL(currentUrl);
  const baseOrigin = urlObj.origin;
  const baseUrl = `${baseOrigin}/comprar`;

  // Join all products with semicolons
  return `${baseUrl}/${products.join(',')}`;
}

function _wpnCreatePhoneModal(originalBtnStyles, messages) {
  const styleElement = document.createElement('style');
  styleElement.textContent = `
    /* Basic modal overlay styling */
    .wpn-wa-modal-overlay {
      display: none;           /* Hidden by default */
      position: fixed;         /* Stay in place */
      z-index: 999999;           /* Sit on top */
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;          /* Enable scroll if needed */
      background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
    }

    /* Modal content box */
    .wpn-wa-modal-content {
      background-color: #fff;
      margin: 10% auto;        /* 10% from the top and centered */
      padding: 20px;
      border-radius: 5px;
      width: 300px;            /* Could be more or less, depending on screen size */
      position: relative;
      text-align: center;
    }

    /* Close button style */
    .wpn-wa-close-btn {
     0 cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      font-weight: bold;
      border: none;
      background: transparent;
    }

    /* Simple button styling */
    .wpn-wa-button {
      cursor: pointer;
      border-radius: 4px;
      border: none;
      font-size: 14px;
    }

    /* Error and success message styles */
    .wpn-wa-error-message {
      color: #d9534f;
      margin-top: 5px;
    }

    .wpn-wa-success-message {
      color: #5cb85c;
      margin-top: 5px;
      display: none; /* hidden by default */
    }
  `;
  document.head.appendChild(styleElement);

  const modalOverlay = document.createElement('div');
  modalOverlay.id = 'wpnWaModalOverlay';
  modalOverlay.classList.add('wpn-wa-modal-overlay');
  document.body.appendChild(modalOverlay);

  const modalContent = document.createElement('div');
  modalContent.classList.add('wpn-wa-modal-content');
  modalOverlay.appendChild(modalContent);

  const closeModalBtn = document.createElement('button');
  closeModalBtn.id = 'wpnWaCloseModalBtn';
  closeModalBtn.classList.add('wpn-wa-close-btn');
  closeModalBtn.classList.add('wpn-wa-button');
  closeModalBtn.innerHTML = '&times;';
  modalContent.appendChild(closeModalBtn);

  const modalTitle = document.createElement('h2');
  modalTitle.textContent = messages.WA_MODAL_TITLE;
  modalContent.appendChild(modalTitle);

  const phoneForm = document.createElement('form');
  phoneForm.id = 'wpnWaPhoneForm';

  const phoneLabel = document.createElement('label');
  phoneLabel.setAttribute('for', 'phoneInput');
  phoneLabel.textContent = messages.WA_MODAL_SUBTITLE;
  phoneForm.appendChild(phoneLabel);

  // Line break
  phoneForm.appendChild(document.createElement('br'));

  const phoneInput = document.createElement('input');
  phoneInput.type = 'tel';
  phoneInput.id = 'wpnWaPhoneInput';
  phoneInput.name = 'phone';
  phoneInput.placeholder = messages.WA_INPUT_PLACEHOLDER;
  phoneInput.required = true;
  // Inline styles
  phoneInput.style.width = 'calc(100% - 16px)';
  phoneInput.style.padding = '8px';
  phoneInput.style.margin = '8px 0';

  phoneForm.appendChild(phoneInput);

  const errorContainer = document.createElement('div');
  errorContainer.id = 'wpnWaErrorContainer';
  errorContainer.classList.add('wpn-wa-error-message');
  phoneForm.appendChild(errorContainer);

  const successContainer = document.createElement('div');
  successContainer.id = 'wpnWaSuccessContainer';
  successContainer.classList.add('wpn-wa-success-message');
  successContainer.textContent = messages.WA_SUCCESS_MESSAGE;
  phoneForm.appendChild(successContainer);

  const submitBtn = document.createElement('button');
  submitBtn.type = 'submit';
  submitBtn.textContent = messages.WA_SUBMIT_BTN_TEXT;
  submitBtn.style.cssText = originalBtnStyles;
  submitBtn.style.float = 'none';
  submitBtn.style.width = '100%';
  phoneForm.appendChild(submitBtn);

  modalContent.appendChild(phoneForm);

  // CLOSE the modal (close button)
  closeModalBtn.addEventListener('click', () => {
    modalOverlay.style.display = 'none';
  });

  // Handle form submission with a simulated AJAX
  phoneForm.addEventListener('submit', async (event) => {
    event.preventDefault(); // Prevent default form submission

    const phoneValue = phoneInput.value.trim();

    // Simulate an AJAX request
    try {
      // Get phone number and current URL
      const cartUrl = _wpnBuildCartUrl();

      const submitDataFormUrl = server.wpnBaseUrl() + '/product/send-product-whatsapp';

      const formData = new URLSearchParams();
      formData.append('phone', phoneValue);
      formData.append('url', cartUrl);
      formData.append('clientId', window['wpnObject'].clientId);
      // Send a fetch request
      fetch(submitDataFormUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded' // Set Content-Type for form-encoded data
        },
        body: formData // Use the formData in the body
      })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              // Show success
              errorContainer.textContent = '';
              successContainer.style.display = 'block';
            } else {
              // Show error
              successContainer.style.display = 'none';
              errorContainer.textContent = data.message || 'An error occurred.';
            }
          })
          .catch((error) => {
            successContainer.style.display = 'none';
            errorContainer.textContent = 'Network or server error. Please try again later.';
          });
    } catch (err) {
      successContainer.style.display = 'none';
      errorContainer.textContent = 'Network or server error. Please try again later.';
    }
  });
}

// Function to create and insert the form
function _wpnOpenPhoneInput(e) {
  document.getElementById('wpnWaModalOverlay').style.display = 'block';
  document.getElementById('wpnWaPhoneForm').reset();
  document.getElementById('wpnWaErrorContainer').textContent = '';
  document.getElementById('wpnWaSuccessContainer').style.display = 'none';
}

// Function to add fade-in effect
function fadeIn(element, duration) {
  element.style.opacity = 0;
  element.style.display = 'block';

  let opacity = 0;
  const increment = 50 / duration;

  function animate() {
    opacity += increment;
    if (opacity >= 1) {
      element.style.opacity = 1;
    } else {
      element.style.opacity = opacity;
      requestAnimationFrame(animate);
    }
  }
  animate();
}


function _wpnGetVariantParameterNumber(nameStr) {
  const pattern = /variation\[(\d+)]/;
  const result = pattern.exec(nameStr);

  if(result !== null && typeof result[1] !== 'undefined'){
    return result[1];
  }
  return false;
}

const enabledWaStores = [2366020, 1935828, 1022935, 767]; //Add here testing, betas for wa
function _wpnHasWhatsappButtonInstalled() {
  return LS && enabledWaStores.includes(LS.store.id) && window['wpnObject'].wapn;
}
