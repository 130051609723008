import {
    _wpnGetPageType,
    _wpnGetCanonicalUrl,
    renderLastVisit,
    renderMostViewed,
    updateLocalHistory,
    renderRecommendedItems,
    renderProductHistory,
    removeWidget,
    clearParsedProductAndPageType
} from '../src/product_detection.js';
import {initialiseState} from '../src/subscription.js';
import * as ui from '../src/ui.js';
import * as browser from '../src/browser.js';
import * as chat from '../src/chat.js';
import * as server from '../src/server.js';
import * as experiments from '../src/experiments';
import * as banners from "../src/banners";
import {_wpnRemoveUtmsFromUrl} from "../src/chat.js";
import {_wpnGetCurrentExperiments} from "../src/experiments";
import * as groovin from "../src/groovin";
import {_wpnEnsureWhatsappButton} from "../src/wapp.js";

const TP_ANALYTICS = process.env.TP_ANALYTICS_CODE;
const USER_EVENT = 1;
const CUSTOM_EVENT = 2;
const CONVERSION_GOAL = 16;
const _WPN_WEB_PAGE_TYPE_THANK_YOU = 2;
const WPN_PAGETIME_EVENT = 4;
const SUBSCRIPTION_DATA_CACHE = 'sdtp';
const SUBSCRIPTION_DATA_CACHE_CONTROL = 'sdtpc';
const CHAT_OPTIONS_CACHE = '_wpn_cotp';
const CHAT_OPTIONS_CACHE_CONTROL = '_wpn_cotpc';
const SUBSCRIPTION_DATA_DIALOG_HTML = 'sddh';
const CLIENT_WEBHOOK_DATA_CACHE_COOKIE = 'cwdcc';
const CLIENT_WEBHOOK_DATA_CACHE_DURATION_DAYS = 1;
const CLIENT_WEBHOOK_DATA_SENT_CACHE_COOKIE = 'cwdscc';
const CLIENT_WEBHOOK_DATA_SENT_CACHE_DURATION = 450;
const BANNERS_ENABLED_CACHE_COOKIE = '_wpnbecc';
const POPUPS_ENABLED_CACHE_COOKIE = '_wpnpecc';
const CURRENT_USER_SUBSCRIBED_COOKIE_NAME = 'cus';
const ACCESS_RETRY_DONE_COOKIE = '_wpn_ard';

const LAST_VISIT_ENABLED_CACHE_COOKIE = '_wpnlvecc';
const PRODUCT_HISTORY_ENABLED_CACHE_COOKIE = '_wpnhecc';
const MOST_VIEWED_ENABLED_CACHE_COOKIE = '_wpnmvecc';
const RECOMMENDED_ITEMS_ENABLED_CACHE_COOKIE = '_wpnriecc';

const CONFIGURATION_ADVANCED = 10;
const CONFIGURATION_CUSTOM = 50;

//device type constants
const DEVICE_PHONE = 1;
const DEVICE_TABLET = 2;
const DEVICE_DESKTOP = 3;

const DECOOKIENAME = '_wpnDestga';

const _wpnObjName = window._WPN || "titanPush";

//Opt-in modes for subscriptors (client's own site or in our domain)
const OPTIN_DEFAULT = 10;
const OPTIN_ONSITE = 20;

const PREVIEW_ADVANCED_FEATURES_COOKIE = "wpnpad";

//Event type constant for the opt-in events to be triggered
const EVENT_TYPE_OPTIN = 21;


//Sets the user some value for a custom dimension and sends the data to WPN
function setUserDimension(clientId, dimensionNumber, dimensionValue) {
    const data = "&dimensionNumber=" + dimensionNumber + "&clientId=" + clientId + "&dimensionValue=" + dimensionValue;
    return server.sendToWPN('/track/set-user-dimension', data);
}

//Increment page view count
function incrementPageViewCount() {
    let currentAmount = browser._wpnGetCookie('wpnViewcount');
    if (currentAmount == null || currentAmount === "") {
        currentAmount = 0;
    }

    browser._wpnSetCookie('wpnViewcount', ++currentAmount);
    return currentAmount;
}


//Check if the current user is subscribed to the WPN service. Returns a promise.
function checkSubscription(clientId, language, forceDialog) {
    forceDialog = forceDialog || false;
    let data = "&clientId=" + clientId + "&lang=" + (language || getLanguageCode());
    let isSubscriptionDataCached = browser._wpnGetCookie(SUBSCRIPTION_DATA_CACHE_CONTROL);

    if (isSubscriptionDataCached == null || isSubscriptionDataCached === false || forceDialog === true ||
        isSubscriptionDataCached === "") {
        return server.sendToWPN('/push-register/check-subscription?cid=' + clientId, data);
    } else {
        return new Promise(function (resolve, reject) {
            let subscriptionData = null;
            if (browser.storageAvailable('localStorage')) {
                subscriptionData = localStorage.getItem(SUBSCRIPTION_DATA_CACHE);
                resolve(JSON.parse(subscriptionData));
            } else {
                subscriptionData = browser._wpnGetCookie(SUBSCRIPTION_DATA_CACHE);
                subscriptionData = JSON.parse(subscriptionData);
                subscriptionData.dialogHtml = browser._wpnGetCookie(SUBSCRIPTION_DATA_DIALOG_HTML);
                resolve(subscriptionData);
            }
        });
    }
}

//Check if the current client has chat enabled and returns the promise
function retrieveChatData(clientId) {
    let data = "&clientId=" + clientId + "&lang=" + getLanguageCode();
    let isDataCached = browser._wpnGetCookie(CHAT_OPTIONS_CACHE_CONTROL);

    let chatData = null;
    if (isDataCached) {
        if (browser.storageAvailable('localStorage')) {
            chatData = localStorage.getItem(CHAT_OPTIONS_CACHE);
        } else {
            chatData = browser._wpnGetCookie(CHAT_OPTIONS_CACHE);
        }
    }

    if (!chatData || isDataCached == null || isDataCached === false) {
        return server.sendToWPN('/chat/get-options', data);
    } else {
        return new Promise(function (resolve, reject) {
            let chatData = null;
            if (browser.storageAvailable('localStorage')) {
                chatData = localStorage.getItem(CHAT_OPTIONS_CACHE);
                resolve(JSON.parse(chatData));
            } else {
                chatData = browser._wpnGetCookie(CHAT_OPTIONS_CACHE);
                resolve(chatData);
            }
        });
    }
}


//Unsuscribe the current user
function unsubscribe(clientId, subscriptorId, token) {
    let data = "&clientId=" + clientId + "&id=" + subscriptorId + "&token=" + token;
    browser._wpnDeleteCookie(SUBSCRIPTION_DATA_CACHE_CONTROL);
    return server.sendToWPN('/push-register/unsubscribe', data);
}

//Returns the language code for the application
function getLanguageCode() {
    return 'es'; //TODO: Should be removed
    //let language = navigator.language || navigator.userLanguage;
    //return language.substr(0, 2);
}


/**** Analytics Functions  ****/


function _wpnGASendEvent(category, action, label, value) {
    if (window['wpnObject'].clientId == 1989 || window['wpnObject'].clientId == 2067 || window['wpnObject'].clientId == 10857 || window['wpnObject'].clientId == 12122) {
        return;
    }
    let wpnGa = getAnalyticsObject();
    if (wpnGa !== undefined) {
        wpnGa('wpnTracker.send', 'event', category, action, label, value);
    }
}

function getAnalyticsObject() {
    if (window['wpnObject'].analyticsObject == null) {
        if (window.GoogleAnalyticsObject !== undefined) {
            window['wpnObject'].analyticsObject = window[window.GoogleAnalyticsObject];
        } else {
            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                }, i[r].l = 1 * new Date();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m)
            })(window['wpnObject'], document, 'script', '//www.google-analytics.com/analytics.js', 'analyticsObject');
        }

        window['wpnObject'].analyticsObject('create', TP_ANALYTICS, 'auto', 'wpnTracker');
    }
    return window['wpnObject'].analyticsObject;
}

/**** End analytics Functions  ****/

function getDomain(url) {
    let pathArray = url.split('/');
    let protocol = pathArray[0];
    let host = pathArray[2];
    return protocol + '//' + host;
}

function dialogDismissCallback(event) {
    browser._wpnSetCookie('wpnLastDenial', Date.now(), 7);
    _wpnGASendEvent('dialog', 'dismiss', window['wpnObject'].clientId);
    browser._wpnDeleteCookie(SUBSCRIPTION_DATA_CACHE_CONTROL);

    document.getElementById('wpnDialogcontainer').remove();
    window['wpnObject'].dialogInitialized = false;

    server.trackEvent(window['wpnObject'].clientId, EVENT_TYPE_OPTIN, "dismiss", null, window.location.href, null, null, _wpnGetCurrentExperiments(),
      _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
}

function bindDialogEvents() {
    let yesBtn = document.getElementById('btnYesIdWpnPush');

    if (yesBtn !== null) {
        yesBtn.addEventListener('click', function (e) {
            browser._wpnDeleteCookie(SUBSCRIPTION_DATA_CACHE_CONTROL);
            _wpnGASendEvent('dialog', 'click', window['wpnObject'].clientId);
            server.trackEvent(window['wpnObject'].clientId, EVENT_TYPE_OPTIN, "open", null, window.location.href, null, null, _wpnGetCurrentExperiments(),
              _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
            document.getElementById('wpnDialogcontainer').remove();
            if (window['wpnObject'].optinMode == OPTIN_ONSITE) {
                server.recoverId().then(function (myId) {
                    initialiseState(null, window['wpnObject'].vapid, window['wpnObject'].clientId, window['wpnObject'].tk, myId);
                });
            } else {
                window['wpnObject'].redirectToUrl();
            }
        });
    }

    let noBtn = document.getElementById('btnNoIdWpnPush');
    if (noBtn !== null) {
        noBtn.addEventListener('click', dialogDismissCallback);
    }
}

function bindDialogCloseOnEsc() {
    document.addEventListener("keyup", function (e) {
        if (e.keyCode === 27) { //TODO: Deprecated. Should be handled better
            dialogDismissCallback(e);
        }
    });
}

/** Webhook functions **/

function sendWebhook(webhookData) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let url = new URL(webhookData.endpoint);
    url.searchParams.append('data', webhookData.data);
    fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: myHeaders
    });
}

function fetchWebhookDataForSubscriptor(clientId) {
    let data = "&clientId=" + clientId;
    return server.sendToWPN('/push-register/get-webhook-data', data);
}

function clientDoesHasWebhooksInCache() {
    let chw = browser._wpnGetCookie(CLIENT_WEBHOOK_DATA_CACHE_COOKIE);
    let clientDoesNotHaveWebhooks = chw != null && chw == "false";
    if (clientDoesNotHaveWebhooks) {
        return true;
    } else {
        return false;
    }
}

function fetchAndSendWebhookDataToClient(clientId) {
    if (!clientDoesHasWebhooksInCache()) {
        fetchWebhookDataForSubscriptor(clientId).then(function (webhookData) {
            browser._wpnSetCookie(CLIENT_WEBHOOK_DATA_CACHE_COOKIE, webhookData.webhooksEnabled, CLIENT_WEBHOOK_DATA_CACHE_DURATION_DAYS);
            if (webhookData.webhook && webhookData.webhook.endpoint)
                sendWebhook(webhookData.webhook);
        });
    }
}

/** End Webhook functions **/

//displays the iframe in order to try a resubscription
function rtstp(url) {
    let iframe = document.createElement('iframe');
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
}

/**** Start the WPN Object  ***/

window['wpnObject'] = {
    clientId: null,
    currentPageViewAmount: null,
    dialog: {},
    queue: [],
    analyticsObject: null,
    language: null,
    country: null,
    reminder: {},
    showReminder: true,
    ready: false,
    isSubscriptionCached: false,
    forceCheckOfSubscription: false,
    unsubscribeToken: null,
    browserAvailable: true,
    browserAvailableChecked: false,
    isPrivateMode: false,
    webhook: null,
    dialogEnabled: true,
    dialogInitialized: false,
    scriptUrl: null,
    optinMode: OPTIN_DEFAULT,
    runWidgets: {},
    groovinId : null,

    checkSubscription: function (forceDialog, useCache) {
        forceDialog = forceDialog || false;
        if (typeof useCache === 'undefined' || useCache == null) {
            useCache = true;
        }

        if (forceDialog || !window['wpnObject'].isSubscriptionCached) {
            //If I need to send a webhook check if the caching control cookies exists and otherwise send data
            let webhookDataSent = browser._wpnGetCookie(CLIENT_WEBHOOK_DATA_SENT_CACHE_COOKIE);
            if (webhookDataSent == null || webhookDataSent === "") {
                fetchAndSendWebhookDataToClient(window['wpnObject'].clientId);
                browser._wpnSetCookieInSeconds(CLIENT_WEBHOOK_DATA_SENT_CACHE_COOKIE, "true", CLIENT_WEBHOOK_DATA_SENT_CACHE_DURATION);
            }
            checkSubscription(window['wpnObject'].clientId, window['wpnObject'].language, forceDialog).then(function (data) {
                    if (data.error === 1) {
                        //Avoid requests in the next hour
                        browser._wpnSetCookieInSeconds(CURRENT_USER_SUBSCRIBED_COOKIE_NAME, true, 3600);
                        return;
                    }

                    if(window['wpnObject'].clientId === 2139 || window['wpnObject'].clientId === '2139'){
                        // Yagmour
                        // Create the popup container
                        const popup = document.createElement('div');
                        popup.style.position = 'fixed';
                        popup.style.bottom = '20px'; // Adds separation from the bottom
                        popup.style.right = '20px'; // Adds separation from the right
                        popup.style.width = '250px';
                        popup.style.padding = '15px';
                        popup.style.backgroundColor = '#E5FDFF'; // Neutral background color
                        popup.style.border = '1px solid transparent'; // Neutral border color
                        popup.style.borderRadius = '8px';
                        popup.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)';
                        popup.style.fontFamily = 'Arial, sans-serif';
                        popup.style.fontSize = '14px';
                        popup.style.color = '#777';
                        popup.style.zIndex = '99999';
                        // Add the message text
                        popup.textContent = 'Script de notificaciones activo. Por favor retire el mismo.';
                        // Append the popup to the body
                        document.body.appendChild(popup);
                    }

                    let minPageViewsToShow = parseInt(data.minPageViewsToShow);
                    let recurringEveryPageViewCount = parseInt(data.recurringEveryPageViewCount);
                    let minDaysBetweenDialogs = parseInt(data.minDaysBetweenDialogs);
                    window['wpnObject'].dialog.msg = data.msg;
                    window['wpnObject'].dialog.url = data.url;
                    window['wpnObject'].dialog.title = data.title;
                    window['wpnObject'].language = data.language;
                    window['wpnObject'].country = data.country;
                    window['wpnObject'].currencyCode = data.currencyCode;
                    window['wpnObject'].dialog.backgroundColor = data.backgroundColor;
                    window['wpnObject'].dialog.displayOptionType = data.displayOptionType;
                    window['wpnObject'].dialog.displayOnlyPages = data.displayOnlyPages;
                    window['wpnObject'].dialog.displayExcludePages = data.displayExcludePages;
                    window['wpnObject'].dialog.yesText = data.yesText;
                    window['wpnObject'].dialog.noText = data.noText;
                    window['wpnObject'].dialog.image = data.image;
                    window['wpnObject'].dialog.ad = data.ad;
                    window['wpnObject'].dialog.terms = data.terms;
                    window['wpnObject'].dialog.reminderIcon = data.reminderIcon;
                    window['wpnObject'].dialog.modalBackgroundColor = data.modalBackgroundColor;
                    window['wpnObject'].dialog.modalFontColor = data.modalFontColor;
                    window['wpnObject'].dialog.testUrl = data.testUrl;
                    window['wpnObject'].dialog.customHtml = data.dialogHtml;
                    window['wpnObject'].dialog.configurationType = data.configurationType;
                    window['wpnObject'].showReminder = data.showReminder;
                    window['wpnObject'].reminder.position = data.reminderPosition;
                    window['wpnObject'].unsubscribeToken = data.unsubscribeToken;
                    window['wpnObject'].dialogEnabled = data.dialogEnabled != 0;
                    window['wpnObject'].scriptUrl = data.scriptUrl;
                    //This is done to avoid multiple entries on the same lifecycle (ie multiple insertions of the script)
                    window['wpnObject'].isSubscriptionCached = true;
                    window['wpnObject'].ready = true;
                    window['wpnObject'].tk = data.tk;
                    window['wpnObject'].vapid = data.vapid;
                    window['wpnObject'].optinMode = data.optinMode || OPTIN_DEFAULT;
                    window['wpnObject'].wapn = data.wapn;
                    window['wpnObject'].wabtnmsg = data.wabtnmsg;
                    window['wpnObject'].storeProvider = data.storeProvider;
                    window['wpnObject'].delayInline = data.delayInline;
                    window['wpnObject'].groovinId = data.groovinId || null;
                    window['wpnObject'].wappUsers = data.wappUsers || [];
                    window['wpnObject'].wappMessages = data.wappMessages || null;

                    //POC groovin ads
                    if(window['wpnObject'].groovinId){
                        groovin.applyTrackings(window['wpnObject'].groovinId, window['wpnObject'].storeProvider);
                    }

                    //Sets the preferred GA property, if any for the client
                    window._wpnPreferredGAProperty = data.preferredGA || null;

                    //TODO Mejorar para ahorrar request de chat si no está habilitado

                    //Si tiene opt in on site (importer, registro el service worker a través del importer.js)
                    if (window['wpnObject'].optinMode == OPTIN_ONSITE) {
                        if ('serviceWorker' in navigator) {
                            let scriptUrl = '/importer.js';
                            let registrationOptions = {};

                            //Enable clients when needed
                            if(window['wpnObject'].clientId == 9131){ //VITAL
                                scriptUrl = '/arquivos/importer.js';
                                registrationOptions.scope = '/';
                            }
                            navigator.serviceWorker.register(scriptUrl, registrationOptions).then(function (registration) {
                            }).catch(function (error) {
                            });
                            //} else {
                            //console.log('Service workers are not supported.');
                        }
                    }

                    //Cache the "checkSubscription" request
                    let isCached = browser._wpnGetCookie(SUBSCRIPTION_DATA_CACHE_CONTROL);
                    if (useCache && (isCached == null || isCached === "")) {
                        browser._wpnSetCookieInSeconds(SUBSCRIPTION_DATA_CACHE_CONTROL, "1", 300);
                        if (browser.storageAvailable('localStorage')) {
                            let strData = JSON.stringify(data);
                            localStorage.setItem(SUBSCRIPTION_DATA_CACHE, strData);
                        } else {
                            //Create a copy of the data so that it is not deleted
                            let newData = JSON.parse(JSON.stringify(data));
                            delete newData[dialogHtml];
                            browser._wpnSetCookieInSeconds(SUBSCRIPTION_DATA_CACHE, JSON.stringify(newData), 320);
                            browser._wpnSetCookieInSeconds(SUBSCRIPTION_DATA_DIALOG_HTML, data.dialogHtml, 320);
                        }
                    }

                    //Cache subscription data
                    browser._wpnSetCookie(CURRENT_USER_SUBSCRIBED_COOKIE_NAME, data.success, 20); //20 days valid for the current

                    let rechecked = browser._wpnGetCookie(ACCESS_RETRY_DONE_COOKIE);
                    if (data.success && (rechecked == null || rechecked === "")) {
                        //Retry to update subscription once a week
                        browser._wpnSetCookie(ACCESS_RETRY_DONE_COOKIE, 1, 7); //Do not retry the susbcription for 7 days
                        rtstp(data.url);
                    }

                    if (window["wpnObject"].browserAvailable && (forceDialog || data.success === false)) { //User not subscribed and browser capable of receiving subscriptions
                        if (forceDialog || ui.dialogShouldBeShown(window['wpnObject'].currentPageViewAmount, minPageViewsToShow,
                            recurringEveryPageViewCount, minDaysBetweenDialogs)) {

                            if (window['wpnObject'].optinMode !== OPTIN_ONSITE || Notification.permission !== 'denied') {
                                //Preload image
                                let img = new Image();
                                img.src = data.image;
                                window['wpnObject'].showDialog();
                            }
                        }
                    }

                    //Check if a given feature is available and runs it. Caches the available value in a cookie for 5 min.
                    const runIfNotCachedByCookie = function (featureEnabled, fnToRun, cookieName, timeInSeconds) {
                        if (featureEnabled) {
                            if (browser._wpnGetCookie(cookieName) == null && typeof window['wpnObject'].runWidgets[cookieName] === 'undefined') {
                                browser._wpnSetCookieInSeconds(cookieName, window['wpnObject'].currentPageViewAmount, timeInSeconds);
                                window['wpnObject'].runWidgets[cookieName] = 1;
                                fnToRun();
                            }
                        } else {
                            browser._wpnDeleteCookie(cookieName);
                        }
                    };


                    //Initialize banners and popups
                    runIfNotCachedByCookie(data.bannersEnabled, function () {
                        banners.renderBanners(window['wpnObject'].clientId);
                    }, BANNERS_ENABLED_CACHE_COOKIE, 300);

                    //Check if popups are available and display them. Caches the available value in a cookie for 5 min.
                    runIfNotCachedByCookie(data.popupsEnabled, function () {
                        banners.renderPopups(window['wpnObject'].clientId);
                    }, POPUPS_ENABLED_CACHE_COOKIE, 300);

                    //Check if banners are available and display them. Caches the available value in a cookie for 5 min.


                    //New widgets of products initialization
                    if(data.lastVisit || data.productHistory){
                        //This widgets save data client side, have to check if an update is needed
                        updateLocalHistory(window['wpnObject'].clientId).then(function(products){
                            runIfNotCachedByCookie(data.lastVisit, function () {
                                renderLastVisit(window['wpnObject'].clientId);
                            }, LAST_VISIT_ENABLED_CACHE_COOKIE, 300);
                            runIfNotCachedByCookie(data.productHistory, function () {
                                renderProductHistory(window['wpnObject'].clientId);
                            }, PRODUCT_HISTORY_ENABLED_CACHE_COOKIE, 300);
                        });
                    }
                    runIfNotCachedByCookie(data.mostViewed, function () {
                        renderMostViewed(window['wpnObject'].clientId);
                    }, MOST_VIEWED_ENABLED_CACHE_COOKIE, 300);
                    runIfNotCachedByCookie(data.recommendedItems, function () {
                        renderRecommendedItems(window['wpnObject'].clientId);
                    }, RECOMMENDED_ITEMS_ENABLED_CACHE_COOKIE, 300); //TODO: Quizás sea necesario tunear algún parámetro de duración de cache

                    //Insert external tracking scripts
                    if (window['wpnObject'].scriptUrl !== "") {
                        window['wpnObject'].insertExternalScript(window['wpnObject'].scriptUrl);
                    }

                    if("consoleMessages" in data && Array.isArray(data["consoleMessages"]) && data["consoleMessages"].length > 0)
                    {
                        data["consoleMessages"].forEach(function(message){
                            if (Array.isArray(message)) {
                                console.log(...message);
                            } else {
                                console.log(message);
                            }
                        });
                    }

                    _wpnEnsureWhatsappButton();

                    return;
                }
            ).catch(function (error) {
            });
        }
    },
    showDialog: function () {
        let currentUrl = window.location.href;
        let hashLocation = currentUrl.indexOf('#');
        if (hashLocation > 0) {
            currentUrl = currentUrl.substr(0, hashLocation)
        }
        let pages = window['wpnObject'].dialog.displayOptionType === browser.DISPLAY_ONLY_PAGES ? window['wpnObject'].dialog.displayOnlyPages : window['wpnObject'].dialog.displayExcludePages;
        if (browser.appliesForCurrentURL(window['wpnObject'].dialog.displayOptionType, pages, currentUrl)) {
            if (!window['wpnObject'].isSubscriptionCached) {
                window['wpnObject'].forceCheckOfSubscription = true;
                window['wpnObject'].checkSubscription();
            } else {
                if (document.readyState === 'complete') {
                    _wpnActuallyShowDialog();
                } else {
                    window.addEventListener('load', function () {
                        _wpnActuallyShowDialog();
                    });
                }
            }
            _wpnGASendEvent('dialog', 'open', window['wpnObject'].clientId);
        }
    },
    pageView: function (url, title) {
        /* We might want to have this if later, to be defined and tested
        if (!window['wpnObject'].isPrivateMode) {  */
        let queryString = '?idClient=' + window['wpnObject'].clientId;
        url = new URL(window.location.href);

        if (url.searchParams.has('gclid')) {
            queryString += '&gclid=' + url.searchParams.get('gclid');
        }

        const pageType = _wpnGetPageType(window['wpnObject'].clientId)
        server.trackEvent(window['wpnObject'].clientId, USER_EVENT, 'pageview', title, url, null, pageType, _wpnGetCanonicalUrl(),
            chat._wpnRemoveUtmsFromUrl(document.referrer), experiments._wpnGetCurrentExperiments(), null, null, queryString);

        //POC smart links
        if(typeof LS === "object" && typeof LS.addToCartEnhanced === "function") {
            function _wpnProcessSmartLink(paramName, callback) {
                const urlParams = new URLSearchParams(window.location.search);
                const paramValue = urlParams.get(paramName);

                if (!paramValue) return;

                try {
                    // Decode from Base64 and then parse JSON
                    const decodedParam = atob(paramValue);
                    const dataArray = JSON.parse(decodedParam);

                    if (dataArray.length === 0) return;

                    // Extract the first element and call the callback immediately
                    const firstElement = dataArray[0];
                    const { pid: firstPid, q: firstQ, v: firstV } = firstElement;

                    if (firstPid && firstQ && firstV !== undefined) {
                        // Attach the event listener to run the callback for the remaining elements
                        const remainingElements = dataArray.slice(1);
                        if(remainingElements.length > 0){
                            document.addEventListener('cart.updated', function _wpnSmartLinkHandler() {
                                remainingElements.forEach(element => {
                                    const {pid, q, v} = element;
                                    if (pid && q && v !== undefined) {
                                        callback(pid, q, Array.isArray(v) ? v : []);
                                    }
                                });

                                // Remove the event listener after processing
                                document.removeEventListener('cart.updated', _wpnSmartLinkHandler);
                            });
                        }
                        callback(firstPid, firstQ, Array.isArray(firstV) ? firstV : []);
                    }
                } catch (error) {
                    console.error('Error processing smart link:', error);
                }
            }

            _wpnProcessSmartLink('wpnc', (pid, q, v) => {
                let inputVariants = '';
                if (v) {
                    for (let i = 0; i < v.length; i++) {
                        inputVariants += `<input type='hidden' name='variation[${i}]' value='${v[i]}'>`
                    }
                }
                let form = `<form><input type='hidden' name='quantity' value='${q}'><input type='hidden' name='add_to_cart' value='${pid}'> '${inputVariants}' </form>`;

                LS.addToCartEnhanced(jQueryNuvem(form));
            })
        }
    },
    event: function (event, value) {
        server.trackEvent(window['wpnObject'].clientId, CUSTOM_EVENT, event, null, null, value, null,
            null, document.referrer, experiments._wpnGetCurrentExperiments());
    },
    conversion: function (orderId) {
        server.trackEvent(window['wpnObject'].clientId, CONVERSION_GOAL, 'goal', null, window.location.href, null, _WPN_WEB_PAGE_TYPE_THANK_YOU, null,
            null, null, null, orderId);
    },
    initChat: function (clientId) {
        retrieveChatData(clientId).then(function (chatData) {
                //Cache the "checkSubscription" request
                if (browser._wpnGetCookie(CHAT_OPTIONS_CACHE_CONTROL) == null || browser._wpnGetCookie(CHAT_OPTIONS_CACHE_CONTROL) === "") {
                    browser._wpnSetCookieInSeconds(CHAT_OPTIONS_CACHE_CONTROL, "1", 300);
                    if (browser.storageAvailable('localStorage')) {
                        let strData = JSON.stringify(chatData);
                        localStorage.setItem(CHAT_OPTIONS_CACHE, strData);
                    } else {
                        //Create a copy of the data so that it is not deleted
                        let newData = JSON.parse(JSON.stringify(chatData));
                        browser._wpnSetCookieInSeconds(CHAT_OPTIONS_CACHE, JSON.stringify(newData), 320);
                    }
                }

                if (chatData !== undefined && chatData !== null && chatData.isEnabled) {
                    let pages = chatData.displayOptionType == browser.DISPLAY_ONLY_PAGES ? chatData.displayOnlyPages : chatData.displayExcludePages;
                    if (browser.appliesForCurrentURL(chatData.displayOptionType, pages, window.location.href)) {
                        chat.displayChatWidget(chatData);
                    }
                }
            }
        );
    },
    initialize: function (clientId, wpnDomain, trackerDomain) {
        if (wpnDomain !== undefined) {
            server.wpnSetBaseUrl('https://' + wpnDomain);
        }

        if (trackerDomain !== undefined) {
            server.wpnSetTrackerBaseUrl('https://' + trackerDomain);
        }
        window['wpnObject'].clientId = clientId;
        window['wpnObject'].currentPageViewAmount = incrementPageViewCount();
        server.recoverId().then(function () {
            if (window['wpnObject'].queue.length > 0) {
                applyPreviousFunctions(window['wpnObject'].queue);
                window['wpnObject'].queue = [];
            }
            //The queue object to send requests to. When defined dispatch the function calls.
            window[_wpnObjName] = function () {
                let functionName = arguments[0];
                let functionArguments = Array.prototype.slice.call(arguments, 1);
                window['wpnObject'][functionName].apply(null, functionArguments);
            };
            window.titanPush = window[_wpnObjName];
            window['wpnObject'].initChat(clientId);

        });

        //TODO: Comentar que de especial tienen esos clientes
        let specialClients = [1952, 2576, 1963, 1892, 1891, 1932, 1897, 1968, 1966];
        if (clientId < 1000 || specialClients.includes(parseInt(clientId))) {
            window._wpnde = false;

            function _wpnDeF(e) {
                if (typeof e.value !== "undefined") {
                    if (!(-1 == e.value.search(/[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+\.[a-z0-9-]+(\.[_a-z0-9-]+)*/i))) {
                        window._wpnde = btoa(e.value.toLocaleLowerCase());
                    }
                }
            };
            document.addEventListener("keyup", function (e) {
                _wpnDeF(e.target)
            });
            window.addEventListener('beforeunload', function (e) {
                if (window._wpnde !== false) {
                    checkDuplicateAndSend();
                    return true;
                }
            });

            function checkDuplicateAndSend() {
                let curVal = window._wpnde;
                let sent = browser._wpnGetCookie(DECOOKIENAME);
                let found = false;
                if (sent == null || sent === "") {
                    browser._wpnSetCookie(DECOOKIENAME, JSON.stringify([curVal]), 60);
                } else {
                    let prev = JSON.parse(sent);
                    found = prev.includes(curVal);

                    if (!found) {
                        prev.push(curVal);
                        browser._wpnSetCookie(DECOOKIENAME, JSON.stringify(prev), 60);
                    }

                }

                if (!found) {
                    _wpnGASendEvent('de', 'found', curVal, clientId);
                }
            }
        }

        //Sets the advanced features flag in order to preview or debug in production based on a cookie
        window['wpnObject'].advancedFeatures = 0;
        let advancedFeatures = browser._wpnGetCookie(PREVIEW_ADVANCED_FEATURES_COOKIE);
        if (advancedFeatures) {
            window['wpnObject'].advancedFeatures = parseInt(advancedFeatures);
        }

        //If the load event was already triggered, run the widgets if there is a cookie cache.
        if (document.readyState === 'complete') {
            _wpnRunWidgetsIfCookiesFound();
        } else {
            //Otherwise, on load (once the document is complete), trigger the running of the widgets
            window.addEventListener('load', function () {
                _wpnRunWidgetsIfCookiesFound();
            });
        }

        window['wpnObject'].detectURLChanges();
    },
    previewHtml: function (html) {
        ui.confirmDialogHtml(html);
    },
    dimension: function (dimensionNumber, dimensionValue) {
        setUserDimension(window['wpnObject'].clientId, dimensionNumber, dimensionValue);
    },
    setLanguage: function (languageCode) {
        window['wpnObject'].language = languageCode;
    },
    redirectToUrl: function () {
        redirectToBasedOnDevice(window['wpnObject'].dialog.url);
    },
    sbox: function (s, t) {
        const el = document.querySelector(s);
        if (el) {
            el.innerHTML = t;
        }
    },
    redirectToTestUrl: function () {
        redirectToBasedOnDevice(window['wpnObject'].dialog.testUrl);
    },
    unsubscribe: function () {
        server.recoverId().then(function (myId) {
            unsubscribe(window['wpnObject'].clientId, myId, window['wpnObject'].unsubscribeToken);
        })
    },
    insertExternalScript: function (url) {
        if (url !== undefined && url !== "") {
            let ts = document.createElement('script');
            ts.type = 'text/javascript';
            ts.async = true;
            ts.src = url;
            let s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ts, s);
        }
    },
    detectURLChanges: function() {
        //On URL changes, fire a pageview TP event if needed as seen with SPA webpages
        function handleDOMChanges(mutationsList, observer) {
            if (window.location.href !== observer.currentUrl && document.title !== observer.pageTitle) {
                observer.currentUrl = window.location.href;
                observer.pageTitle = document.title;
                //It's better to wait for a second or so; as the page might not be fully loaded yet
                setTimeout(function () {
                    clearParsedProductAndPageType();
                    window['wpnObject'].pageView(window.location.href, document.title);
                    //Product and recommendations are based on the new page URL, make sure the existing one is removed
                    removeWidget('h');
                    removeWidget('ri');
                    if (window['wpnObject'].clientId !== null) {
                        renderProductHistory(window['wpnObject'].clientId);
                        renderRecommendedItems(window['wpnObject'].clientId);
                    }
                }, 1800);
            }
        }

        // Create and configure a mutation observer to detect changes in the DOM
        const observer = new MutationObserver(handleDOMChanges);
        const observerConfig = {attributes: true, childList: true, subtree: true};

        // Store the current URL and page title so that we can detect changes
        observer.currentUrl = window.location.href;
        observer.pageTitle = document.title;

        // Set the observer with its config
        observer.observe(document.body, observerConfig);
    },
};

//Runs widgets according to cookies data
function _wpnRunWidgetsIfCookiesFound()
{
    //Runs function if cookie is found
    const runIfCookieFound = function (fnToRun, cookieName) {
        let cookieVal = browser._wpnGetCookie(cookieName);
        if (cookieVal !== null && cookieVal !== window['wpnObject'].currentPageViewAmount && typeof window['wpnObject'].runWidgets[cookieName] === 'undefined') {
            window['wpnObject'].runWidgets[cookieName] = 1;
            fnToRun();
        }
    }
    runIfCookieFound(function () {
        banners.renderBanners(window['wpnObject'].clientId);
    }, BANNERS_ENABLED_CACHE_COOKIE);

    runIfCookieFound(function () {
        banners.renderPopups(window['wpnObject'].clientId);
    }, POPUPS_ENABLED_CACHE_COOKIE);

    //Manual check to avoid generating not useful requests to the server
    if (browser._wpnGetCookie(LAST_VISIT_ENABLED_CACHE_COOKIE) || browser._wpnGetCookie(PRODUCT_HISTORY_ENABLED_CACHE_COOKIE)) {
        //This widgets save data client side, have to check if an update is needed
        updateLocalHistory(window['wpnObject'].clientId).then(function (products) {
            runIfCookieFound(function () {
                renderLastVisit(window['wpnObject'].clientId);
            }, LAST_VISIT_ENABLED_CACHE_COOKIE);
            runIfCookieFound(function () {
                renderProductHistory(window['wpnObject'].clientId);
            }, PRODUCT_HISTORY_ENABLED_CACHE_COOKIE);
        });
    }

    runIfCookieFound(function () {
        renderMostViewed(window['wpnObject'].clientId);
    }, MOST_VIEWED_ENABLED_CACHE_COOKIE);
    runIfCookieFound(function () {
        renderRecommendedItems(window['wpnObject'].clientId);
    }, RECOMMENDED_ITEMS_ENABLED_CACHE_COOKIE);
}


function _wpnActuallyShowDialog() {
    if (window['wpnObject'].dialogEnabled) {
        if (window['wpnObject'].dialogInitialized === false) {
            window['wpnObject'].dialogInitialized = true;
            /** Comment out for now
            //Preload the next page
            let preloadLink = document.createElement("link");
            preloadLink.href = window['wpnObject'].dialog.url;
            preloadLink.rel = "prefetch";
            preloadLink.as = "html";
            document.head.appendChild(preloadLink);

            //Preload the next page
            let manifestPreloadLink = document.createElement("link");
            manifestPreloadLink.href = getDomain(window['wpnObject'].dialog.url) + '/manifest.json';
            manifestPreloadLink.rel = "prefetch";
            manifestPreloadLink.as = "json";
            document.head.appendChild(manifestPreloadLink);
            **/
            let dialog = window['wpnObject'].dialog;
            if (window['wpnObject'].dialog.configurationType == CONFIGURATION_CUSTOM) {
                ui.confirmDialogHtml(dialog.customHtml);
            } else {
                if (browser._wpnGetDeviceType() == DEVICE_DESKTOP) {
                    ui.confirmDialog(dialog);
                } else {
                    ui.confirmDialogMobile(dialog);
                }
            }

            bindDialogEvents();
            bindDialogCloseOnEsc();
            server.trackEvent(window['wpnObject'].clientId, EVENT_TYPE_OPTIN, "view", null, window.location.href, null, null, _wpnGetCurrentExperiments(),
              _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
        }
    }
}

function redirectToBasedOnDevice(url) {
    let dt = browser._wpnGetDeviceType();

    //TODO ESTO EStaba así, funcioan sin browswer.DEVICE_DESKTOP
    if (dt == DEVICE_DESKTOP) {
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        let left = ((width / 2) - 250) + dualScreenLeft;
        let top = ((height / 2) - 150) + dualScreenTop;

        window.open(url, 'ask-subscription', 'status=0, toolbar=0, menubar = 0, scrollbars = 0, width = 500, height = 240, top = ' + top + ", left= " + left);
    } else {
        window.location.href = url;
    }
    try {
        document.getElementById('wpnOverlayId').remove();
        document.getElementById('wpnDialogId').remove();
        window['wpnObject'].dialogInitialized = false;
    } catch (e) {
    }
}


function applyPreviousFunctions(functionsArray) {
    for (let i = 0; i < functionsArray.length; i++) {
        let currentElement = functionsArray[i];
        let functionName = currentElement[0];
        let functionArguments = Array.prototype.slice.call(currentElement, 1);
        window['wpnObject'][functionName].apply(null, functionArguments);
    }
}

function callInitializeIfNeeded() {
    for (let i = 0; i < window['wpnObject'].queue.length; i++) {
        let currentElement = window['wpnObject'].queue[i];
        let functionName = currentElement[0];
        if (functionName == 'initialize') {
            window['wpnObject'].queue.splice(i, 1); //remove current position before calling initialize so to avoid multiple initialize calls
            let functionArguments = Array.prototype.slice.call(currentElement, 1);
            window['wpnObject'][functionName].apply(null, functionArguments);
        }
    }
}

//Tries to validate if the browser is in private mode or if it is blacklisted (safari).
function doIfBrowserAvailable(callback) {
    if (!window['wpnObject'].browserAvailableChecked) {
        browser.detectPrivateMode().then(function (isPrivateMode) {
            window['wpnObject'].browserAvailable = browser.isBrowserAvailable(isPrivateMode);
            window['wpnObject'].browserAvailableChecked = true;
            window['wpnObject'].privateMode = isPrivateMode;
            callback();
        });
    } else {
        callback();
    }
}

let _wpnHasBeenCalled = window._wpnHasBeenCalled || false;
if (_wpnHasBeenCalled == false) {
    window._wpnHasBeenCalled = true;
    doIfBrowserAvailable(function () {
        if (window[_wpnObjName] !== undefined && window[_wpnObjName].q !== undefined && window[_wpnObjName].q.constructor === Array) {
            window['wpnObject'].queue = window['wpnObject'].queue.concat(window[_wpnObjName].q);
        } else if (window.titanPush !== undefined && window[_wpnObjName].q !== undefined && window.titanPush.q.constructor === Array) {
            window['wpnObject'].queue = window['wpnObject'].queue.concat(window.titanPush.q);
        }

        //The queue object to send requests to. When defined dispatch the function calls.
        window[_wpnObjName] = function () {
            let functionName = arguments[0];
            if (functionName == 'initialize') {
                let functionArguments = Array.prototype.slice.call(arguments, 1);
                window['wpnObject'][functionName].apply(null, functionArguments);
            } else {
                window['wpnObject'].queue.push(arguments);
            }
        };

        callInitializeIfNeeded();
    });
}